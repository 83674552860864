<template>
<div>

    <b-row>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-edit fa-md"></i> <span class="h5"> Evaluación riesgos</span>
                </CCardHeader>
                <CCardBody>
                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                        <b-form @submit.stop.prevent="handleSubmit(continuarEvaluacion)">
                            <b-row>
                                <b-col md="6">
                                    <validation-provider name="fecha" :rules="{required: true}" v-slot="validationContext">
                                        <b-form-group label="Fecha:" class="mb-2">
                                            <b-form-input type="date" :state="getValidationState(validationContext)" v-model.lazy="datosNuevaEvaluacion.fecha"></b-form-input>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group label="Documentación:" class="mb-2">
                                        <b-input-group>
                                            <b-input-group-prepend v-if="datosNuevaEvaluacion.urlDocumentacion != ''">
                                                <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosNuevaEvaluacion.urlDocumentacion)" v-c-tooltip="'Descargar'">
                                                    <i class="fas fa-download fa-xs"></i>
                                                </b-button>
                                            </b-input-group-prepend>
                                            <b-form-file ref="file" v-model.lazy="datosNuevaEvaluacion.documentacion" v-on:change="handleFileUpload" :placeholder="datosNuevaEvaluacion.nombreDocumentacion ? datosNuevaEvaluacion.nombreDocumentacion : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="datosNuevaEvaluacion.nombreDocumentacion ? datosNuevaEvaluacion.nombreDocumentacion : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                            
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col md="12" v-if="datosNuevaEvaluacion.idEvaluacionRiesgo!=''">
                                    <validation-provider name="motivo de revisión" :rules="{max:255}" v-slot="validationContext">
                                        <b-form-group label="Motivo de revisión:" class="mb-2">
                                            <b-form-textarea v-model.lazy="datosNuevaEvaluacion.motivoRevision" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese motivo de revisión"></b-form-textarea>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                            </b-row>

                            <!-- <b-col class="mt-3" md="12">
                                <span class="h6 text-muted">POR FAVOR, AGREGUE LOS RIESGOS QUE DESEA EVALUAR</span>
                                <CButton size="sm" class="float-right" color="dark" @click="modalAgregarRiesgos = true">
                                    <i class="fas fa-plus fa-sm"></i> Agregar riesgos
                                </CButton>
                            </b-col> -->
                            <b-col class="mt-3">
                                <hr>
                            </b-col>
                            <b-col md="12" class="mt-3">
                                <template>
                                    <div class="accordion" role="tablist" v-for="(riesgo, k) in datosAcordionRiesgos" :key="k">
                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 " :class="riesgo.tipo=='2' ? 'bg-accordion-transparent' : riesgo.prioridad=='TOLERABLE' ? 'bg-accordion-success' : riesgo.prioridad=='SIGNIFICATIVO' ? 'bg-accordion-warning' : riesgo.prioridad=='INTOLERABLE' ? 'bg-accordion-danger' : 'bg-accordion'" role="tab">
                                                <div block v-b-toggle="'accordion-'+riesgo.idRiesgo" :class="riesgo.tipo=='2' ? 'bg-accordion-transparent' : riesgo.prioridad=='TOLERABLE' ? 'bg-accordion-success' : riesgo.prioridad=='SIGNIFICATIVO' ? 'bg-accordion-warning' : riesgo.prioridad=='INTOLERABLE' ? 'bg-accordion-danger' : 'bg-accordion'">
                                                    <span class=" h6"> {{riesgo.riesgo}}</span>
                                                    <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse :id="'accordion-'+riesgo.idRiesgo" accordion="my-accordion" role="tabpanel">
                                                <b-card-body>
                                                    <b-row>
                                                        <b-col md="6">
                                                            <b-row>
                                                                <b-col md="12">
                                                                    <validation-provider :name="'descripción de riesgo de '+riesgo.riesgo" :rules="{   }" v-slot="validationContext">
                                                                        <b-form-group label="Descripción del riesgo:" class="mb-2">
                                                                            <b-form-textarea v-model.lazy="datosAcordionRiesgos[k].descripcionRiesgo" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción"></b-form-textarea>
                                                                        </b-form-group>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </validation-provider>
                                                                </b-col>
                                                                <b-col>
                                                                    <b-form-group v-if="riesgo.tipo=='1'" label="Prioridad:" class="mb-2">
                                                                        <b-form-input class="disabled-white" v-model.lazy="datosAcordionRiesgos[k].prioridad" disabled type="text">
                                                                        </b-form-input>
                                                                        <!-- <b-form-input class="disabled-white" v-model.lazy="SIGNIFICATIVO" disabled type="text">
                                                                        </b-form-input>
                                                                        <b-form-input class="disabled-white" v-model.lazy="INTOLERABLE" disabled type="text">
                                                                        </b-form-input> -->
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-row>
                                                        </b-col>
                                                        <b-col md="6">
                                                            <b-row v-if="riesgo.tipo=='1'">
                                                                <b-col md="12">
                                                                    <validation-provider :name="'probabilidad de '+riesgo.riesgo" :rules="{ required: true }" v-slot="validationContext">
                                                                        <b-form-group label="Probabilidad:" class="mb-2">
                                                                            <b-form-select v-on:change="generarPrioridad(k)" :options="listaProbalidad" v-model.lazy="datosAcordionRiesgos[k].probabilidad" :state="getValidationState(validationContext)">
                                                                                <template v-slot:first>
                                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                                </template>
                                                                            </b-form-select>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                                <b-col md="12">
                                                                    <validation-provider :name="'consecuencias de riesgo de '+riesgo.riesgo" :rules="{ required: true }" v-slot="validationContext">
                                                                        <b-form-group label="Consecuencias:" class="mb-2">
                                                                            <b-form-select v-on:change="generarPrioridad(k)" :options="listaConsecuencias" v-model.lazy="datosAcordionRiesgos[k].consecuencia" :state="getValidationState(validationContext)">
                                                                                <template v-slot:first>
                                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                                </template>
                                                                            </b-form-select>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                            </b-row>
                                                        </b-col>
                                                        <b-col md="12" class="mt-2">
                                                            <b-table :items="datosAcordionRiesgos[k].listaGestiones.filter(x => x.estado == '2')" :fields="cabeceraGestiones" class="table-custom" mediun responsive outlined fixed>
                                                                <template v-slot:cell(gestiones)="row">
                                                                    <b-row>
                                                                        <b-col md="3">
                                                                            <b-form-group label="Fecha de gestión:" class="mb-2">
                                                                                <b-form-input v-model.lazy="row.item.fechaGestion" type="date"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-col>
                                                                        <b-col md="7">
                                                                            <b-form-group label="Documentación:" class="mb-2">
                                                                                <b-input-group>
                                                                                    <b-input-group-prepend v-if="row.item.urlDocumentacion != ''">
                                                                                        <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(row.item.urlDocumentacion)" v-c-tooltip="'Descargar'">
                                                                                            <i class="fas fa-download fa-xs"></i>
                                                                                        </b-button>
                                                                                    </b-input-group-prepend>
                                                                                    <b-form-file :placeholder="row.item.nombreDocumentacion ? row.item.nombreDocumentacion : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="row.item.nombreDocumentacion ? row.item.nombreDocumentacion : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir" v-model.lazy="row.item.documentacion"></b-form-file>

                                                                                </b-input-group>
                                                                            </b-form-group>
                                                                        </b-col>
                                                                        <b-col md="2" class="text-right mt-3">
                                                                            <b-button @click="quitarGestion(row, k)" v-if="datosAcordionRiesgos[k].listaGestiones.filter(x => x.estado == '2').length >= 2" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                               <i class="fas fa-trash-alt  my-0 mx-0 mr-1"></i> Eliminar gestión
                                                                            </b-button>
                                                                            <!-- <b-button @click="eliminarGestion(row, k)" v-if="datosAcordionRiesgos[k].listaGestiones.length >= 2 && row.item.idEvaluacionDetalleGestion!=''" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                               <i class="fas fa-trash-alt  my-0 mx-0 mr-1"></i> Eliminar gestión
                                                                            </b-button> -->
                                                                        </b-col>
                                                                        <b-col md="12" class="mt-2">
                                                                            <span class="h6 text-muted">Medidas a implantar</span>
                                                                            <CButton size="sm" class="float-right" color="dark" @click="agregarMedidaImplantar(row, k)">
                                                                                <i class="fas fa-plus fa-sm"></i> Agregar medida
                                                                            </CButton>
                                                                        </b-col>
                                                                        <b-col md="12" class="mt-2">
                                                                            <b-table :items="row.item.listaMedidas.filter(x => x.estado == '2')" :fields="cabeceraMedidas" class="table-custom" fixed responsive show-empty mediun outlined hover empty-text="Aún no hay datos en esta sección.">
                                                                                <template #table-colgroup="cabeceraMedidas">
                                                                                    <col v-for="field in cabeceraMedidas.fields" :key="field.key" :style="{ width: field.key === 'responsable' ? '30%' : 'recomendacion' ? '40%' : '10%' }">
                                                                                </template>
                                                                                <template v-slot:cell(responsable)="rowMedidas">
                                                                                    <v-select :reduce="comboCargoResponsable =>comboCargoResponsable.idPuestoTrabajo" label="puestoTrabajo" placeholder="Seleccione una opción" v-model.lazy="rowMedidas.item.idPuestoTrabajo" :options="comboCargoResponsable">
                                                                                        <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Puestos de trabajo'}"> Registra aquí</router-link></span>
                                                                                    </v-select>
                                                                                    <v-select :reduce="comboResponsable =>comboResponsable.idEmpleado" class="mt-2" label="nombres" placeholder="Seleccione una opción" v-model.lazy="rowMedidas.item.idEmpleado" :options="comboResponsable">
                                                                                        <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Recursos humanos'}"> Registra aquí</router-link></span>
                                                                                    </v-select>
                                                                                </template>
                                                                                <template v-slot:cell(recomendacion)="rowMedidas">
                                                                                    <b-form-textarea v-model.lazy="rowMedidas.item.recomendacion" placeholder="Ingrese la recomendación" rows="3" max-rows="6"></b-form-textarea>
                                                                                </template>
                                                                                <template v-slot:cell(plazo)="rowMedidas">
                                                                                    <b-form-select v-model.lazy="rowMedidas.item.plazo" :options="comboPlazo" value-field="idPlazo" text-field="descripcion">
                                                                                        <template v-slot:first>
                                                                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                                        </template>
                                                                                    </b-form-select>
                                                                                </template>
                                                                                <template v-slot:cell(ejecutada)="rowMedidas">
                                                                                    <b-form-checkbox plain :id="'checkbox-'+k+'-'+row.index+'-'+rowMedidas.index" v-model.lazy="rowMedidas.item.ejecutada" :name="'checkbox-'+k+'-'+row.index+'-'+rowMedidas.index" value="2" unchecked-value="1"></b-form-checkbox>
                                                                                </template>
                                                                                <template #cell(acciones)="param">
                                                                                    <b-button @click="quitarMedidaImplantar(param, row, k)" v-if="datosAcordionRiesgos[k].listaGestiones[row.index].listaMedidas.filter(x => x.estado == '2').length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                                                    </b-button>
                                                                                    <!-- <b-button @click="quitarMedidaImplantar(param, row, k)" v-if="datosAcordionRiesgos[k].listaGestiones[row.index].listaMedidas.length >= 2 && param.item.idEvaluacionDetalleMedida!=''" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                                                    </b-button> -->
                                                                                </template>
                                                                            </b-table>
                                                                        </b-col>
                                                                    </b-row>
                                                                </template>
                                                            </b-table>
                                                        </b-col>
                                                        <b-col md="12" class="text-center mt-2">
                                                            <CButton size="sm" color="dark" @click="agregarGestion(k)">
                                                                <i class="fas fa-plus fa-sm"></i> Agregar gestión
                                                            </CButton>
                                                        </b-col>
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                </template>
                            </b-col>
                            <b-col class="my-2 text-center" md="12">
                                <!-- <b-button class="mr-2" :to="{name: 'Selección riesgos'}" variant="dark">
                                    <i class="fas fa-arrow-left"></i> Volver
                                </b-button> -->
                                <b-button v-if="$route.params.id!=null" :to="{name: 'Selección riesgos', params: {id: $route.params.id}}" variant="dark" class=" mr-2">
                                    <i class="fas fa-arrow-left"></i> Volver
                                </b-button>
                                <b-button v-if="$route.params.id==null" :to="{name: 'Selección riesgos'}" variant="dark" class=" mr-2">
                                    <i class="fas fa-arrow-left"></i> Volver
                                </b-button>
                                <b-button :disabled="disabled" v-if="datosAcordionRiesgos.length>0" type="submit" variant="success" class=" mr-2">
                                    <i class="fas fa-arrow-right"></i> Continuar
                                </b-button>

                            </b-col>
                        </b-form>
                    </validation-observer>
                </CCardBody>
            </CCard>
        </b-col>
    </b-row>

    <!-- <CModal :closeOnBackdrop="false" size="lg" color="primario" :show.sync="modalAgregarRiesgos">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Agregar riesgos </span>
            </h6>
            <CButtonClose @click="modalAgregarRiesgos = false" class="text-white" />
        </template>
        <b-col md="12">
            <span class="h6 text-muted">POR FAVOR, SELECCIONE LOS RIESGOS QUE DESEA EVALUAR:</span>
            <b-button v-if="!BtnMostrar" variant="dark" class="float-right mr-2" size="sm" @click="selectAllRows">Seleccionar todos</b-button>
            <b-button v-else-if="BtnMostrar" variant="dark" class="float-right mr-2" size="sm" @click="clearSelected">Limpiar selección</b-button>
            <hr>
        </b-col>
        <b-col md="12" class="mt-3">
            <b-table :items="listaRiesgos" :fields="cabecera" ref="selectableTable" selectable @row-selected="onRowSelected" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                <template #cell(selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                        <span aria-hidden="true">&check;</span>
                        <span class="sr-only">Seleccionado</span>
                    </template>
                    <template v-else>
                        <span aria-hidden="true">&nbsp;</span>
                        <span class="sr-only">No seleccionado</span>
                    </template>
                </template>
            </b-table>
        </b-col>

        <b-col class="my-2 text-right" md="12">
            <b-button variant="success" class=" mr-2" @click="agregarRiesgos()">
                Agregar
            </b-button>
            <b-button variant="danger" @click="modalAgregarRiesgos = false">
                Cancelar
            </b-button>
        </b-col>

        <template #footer>
            <span></span>
        </template>
    </CModal> -->

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {
            modalAgregarRiesgos: false,
            BtnMostrar: false,
            cabecera: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "riesgo",
                    label: "Riesgo",
                    class: "text-center",
                },
                {
                    key: "selected",
                    label: "Selección",
                    class: "text-center",
                }
            ],
            cabeceraGestiones: [{
                key: 'gestiones',
                label: 'Gestiones',
            }],
            cabeceraMedidas: [{
                key: 'responsable',
                label: 'Responsable',
                class: 'text-center'
            }, {
                key: 'recomendacion',
                label: 'Recomendación',
                class: 'text-center'
            }, {
                key: 'plazo',
                label: 'Plazo',
                class: 'text-center'
            }, {
                key: 'ejecutada',
                label: 'Ejecutada',
                class: 'text-center'
            }, {
                key: 'acciones',
                label: '',
                class: 'text-center'
            }],
            listaRiesgos: [],

            arrayRiesgos: [],
            datosAcordionRiesgos: [],
            datosNuevaEvaluacion: {
                idEvaluacionRiesgo: '',
                fecha: moment().format('YYYY-MM-DD'),
                motivoRevision: '',
                documentacion: null,
                urlDocumentacion: '',
                nombreDocumentacion: '',
            },
            listaProbalidad: [{
                value: 1,
                text: 'Remota'
            }, {
                value: 2,
                text: 'Inusual'
            }, {
                value: 3,
                text: 'Ocasional'
            }, {
                value: 4,
                text: 'Frecuente'
            }],
            listaConsecuencias: [{
                value: 1,
                text: 'Moderada'
            }, {
                value: 2,
                text: 'Relevante'
            }, {
                value: 3,
                text: 'Grave'
            }, {
                value: 4,
                text: 'Catastrófica'
            }],
            comboPlazo: [{
                idPlazo: '1',
                descripcion: '6 Meses'
            }, {
                idPlazo: '2',
                descripcion: '12 Meses'
            }, {
                idPlazo: '3',
                descripcion: '18 Meses'
            }, {
                idPlazo: '4',
                descripcion: 'Otros'
            }],
            comboCargoResponsable: [],
            comboResponsable: [],
            datosSession: {
                idCliente: ''
            },
            disabled: false,
            datosEvaluacionDetalleGestion: [],
            datosEvaluacionDetalleMedida: [],

        }
    },
    methods: {
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        descargarDocumento(url) {
            window.open(url)
        },
        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file"].reset();
                    return;
                }

                this.datosNuevaEvaluacion.documentacion = this.$refs.file.files[0];
            }
        },
        blurPuestoTrabajo() {
            this.computedForm.refs.cargo.validate();
        },
        agregarRiesgos() {
            let me = this;
            let arrayRiesgos = me.$route.params.arrayRiesgos;
            for (const i in arrayRiesgos) {
                me.datosAcordionRiesgos.push({
                    idEvaluacionDetalleRiesgo: '',
                    idEvaluacionRiesgo: '',
                    idRiesgo: arrayRiesgos[i].idRiesgo,
                    riesgo: arrayRiesgos[i].riesgo,
                    tipo: arrayRiesgos[i].tipo,
                    descripcionRiesgo: '',
                    probabilidad: null,
                    consecuencia: null,
                    prioridad: '',
                    listaGestiones: [{
                        idEvaluacionDetalleGestion: '',
                        idEvaluacionDetalleRiesgo: '',
                        fechaGestion: moment().format('YYYY-MM-DD'),
                        listaMedidas: [{
                            idEvaluacionDetalleMedida: '',
                            idEvaluacionDetalleGestion: '',
                            idPuestoTrabajo: '',
                            idEmpleado: '',
                            recomendacion: '',
                            plazo: null,
                            ejecutada: '1',
                            estado: 2,
                        }],
                        documentacion: null,
                        urlDocumentacion: '',
                        nombreDocumentacion: '',
                        estado: 2,
                    }],
                });
            }
        },
        consultarEvaluacionRiesgo() {
            let me = this;
            let arrayRiesgos = me.$route.params.arrayRiesgos;
            me.datosAcordionRiesgos = arrayRiesgos;

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/consultar-evaluacion-riesgo", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                            idEvaluacionRiesgo: me.$route.params.id,
                        },
                    }
                )
                .then(function (response) {
                    me.datosNuevaEvaluacion.idEvaluacionRiesgo = response.data[0].idEvaluacionRiesgo;
                    me.datosNuevaEvaluacion.fecha = response.data[0].fecha;
                    me.datosNuevaEvaluacion.motivoRevision = response.data[0].motivoRevision;
                    me.datosNuevaEvaluacion.urlDocumentacion = response.data[0].urlDocumentacion;
                    me.datosNuevaEvaluacion.nombreDocumentacion = response.data[0].nombreDocumentacion;

                    for (const j in arrayRiesgos) {
                        for (const i in response.data) {
                            if (arrayRiesgos[j].idRiesgo == response.data[i].idRiesgo) {
                                me.datosAcordionRiesgos.splice(j, 1, {
                                    idEvaluacionDetalleRiesgo: response.data[i].idEvaluacionDetalleRiesgo,
                                    idEvaluacionRiesgo: response.data[i].idEvaluacionRiesgo,
                                    idRiesgo: response.data[i].idRiesgo,
                                    riesgo: response.data[i].riesgo,
                                    tipo: response.data[i].tipo,
                                    descripcionRiesgo: response.data[i].descripcionRiesgo,
                                    probabilidad: response.data[i].probabilidad,
                                    consecuencia: response.data[i].consecuencia,
                                    prioridad: response.data[i].prioridad == 1 ? 'TOLERABLE' : response.data[i].prioridad == 2 ? 'SIGNIFICATIVO' : response.data[i].prioridad == 3 ? 'INTOLERABLE' : '',
                                    listaGestiones: [],
                                })
                            }
                        }
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        consultarEvaluacionDetalleGestion() {
            let me = this;

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/consultar-evaluacion-detalle-gestion", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                            idEvaluacionRiesgo: me.$route.params.id,
                        },
                    }
                )
                .then(function (response) {
                    //me.datosEvaluacionDetalleGestion = response.data;
                    for (const i in response.data) {
                        me.datosEvaluacionDetalleGestion.push({
                            idEvaluacionDetalleGestion: response.data[i].idEvaluacionDetalleGestion,
                            idEvaluacionDetalleRiesgo: response.data[i].idEvaluacionDetalleRiesgo,
                            fechaGestion: response.data[i].fechaGestion,
                            listaMedidas: [],
                            documentacion: null,
                            urlDocumentacion: response.data[i].urlDocumentacion,
                            nombreDocumentacion: response.data[i].nombreDocumentacion,
                            estado: response.data[i].estado,
                        })
                    }

            
                    me.consultarEvaluacionDetalleMedida();

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        consultarEvaluacionDetalleMedida() {
            let me = this;

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/consultar-evaluacion-detalle-medida", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                            idEvaluacionRiesgo: me.$route.params.id,
                        },
                    }
                )
                .then(function (response) {
                    me.datosEvaluacionDetalleMedida = response.data;
                    for (const j in me.datosEvaluacionDetalleGestion) {
                        for (const k in me.datosEvaluacionDetalleMedida) {
                            if (me.datosEvaluacionDetalleGestion[j].idEvaluacionDetalleGestion == me.datosEvaluacionDetalleMedida[k].idEvaluacionDetalleGestion) {
                                me.datosEvaluacionDetalleGestion[j].listaMedidas.push({
                                    idEvaluacionDetalleMedida: me.datosEvaluacionDetalleMedida[k].idEvaluacionDetalleMedida,
                                    idEvaluacionDetalleGestion: me.datosEvaluacionDetalleMedida[k].idEvaluacionDetalleGestion,
                                    idPuestoTrabajo: me.datosEvaluacionDetalleMedida[k].idCargoResponsable,
                                    idEmpleado: me.datosEvaluacionDetalleMedida[k].idResponsable,
                                    recomendacion: me.datosEvaluacionDetalleMedida[k].recomendacion,
                                    plazo: me.datosEvaluacionDetalleMedida[k].plazo,
                                    ejecutada: me.datosEvaluacionDetalleMedida[k].ejecutada,
                                    estado: me.datosEvaluacionDetalleMedida[k].estado,
                                })
                            }
                        }

                    }
                    for (const i in me.datosAcordionRiesgos) {
                        for (const j in me.datosEvaluacionDetalleGestion) {
                            if (me.datosAcordionRiesgos[i].idEvaluacionDetalleRiesgo == me.datosEvaluacionDetalleGestion[j].idEvaluacionDetalleRiesgo) {
                                me.datosAcordionRiesgos[i].listaGestiones.push(me.datosEvaluacionDetalleGestion[j]);
                            }
                        }
                    }

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarPuestosTrabajo() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-puestos-trabajo", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboCargoResponsable = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarEmpleados() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-empleados", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboResponsable = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        continuarEvaluacion() {
            let me = this;
            if (me.$route.params.id) {
                me.$router.push({
                    name: 'Generar gráfico evaluación',
                    params: {
                        id: me.$route.params.id,
                        datosAcordionRiesgos: me.datosAcordionRiesgos,
                        datosNuevaEvaluacion: me.datosNuevaEvaluacion
                    }
                });
            } else {
                me.$router.push({
                    name: 'Generar gráfico evaluación',
                    params: {
                        datosAcordionRiesgos: me.datosAcordionRiesgos,
                        datosNuevaEvaluacion: me.datosNuevaEvaluacion
                    }
                });
            }
            //me.disabled = true;
            // const formData = new FormData();
            // /*---Necesario para ruta de archivo --*/
            // formData.append("file", me.datosNuevaEvaluacion.documentacion);
            // formData.append("folder", 'riesgos/evaluacion-riesgos');

            // for (const i in me.datosAcordionRiesgos) {
            //     for (const j in me.datosAcordionRiesgos[i].listaGestiones) {
            //         if (me.datosAcordionRiesgos[i].listaGestiones[j].documentacion != null) {
            //             formData.append("fileaa" + i + "aa" + j, me.datosAcordionRiesgos[i].listaGestiones[j].documentacion);
            //         }

            //     }
            // }
            // formData.append("folder2", 'riesgos/evaluacion-riesgos/gestiones-documentos');

            // formData.append("datosNuevaEvaluacion", JSON.stringify(me.datosNuevaEvaluacion));
            // formData.append("datosEvaluacionDetalle", JSON.stringify(me.datosAcordionRiesgos));
            // formData.append("idCliente", me.datosSession.idCliente);

            // axios
            //     .post(
            //         CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-evaluacion-riesgo",
            //         formData, {
            //             headers: {
            //                 Authorization: `Bearer ${localStorage.token}`
            //             },
            //         }
            //     )
            //     .then(function (response) {
            //         let color = response.data.resultado == 1 ? "success" : "error";
            //         me.swat(color, response.data.mensaje);
            //         me.$router.push({
            //             name: 'Evaluación de riesgos',
            //         });
            //         me.disabled = false;
            //     })
            //     .catch(function (error) {
            //         me.swat('error', 'Algo salió mal!')
            //         me.disabled = false;
            //     });
        },
        agregarMedidaImplantar(row, k) {
            let me = this;
            me.datosAcordionRiesgos[k].listaGestiones[row.index].listaMedidas.push({
                idEvaluacionDetalleMedida: '',
                idEvaluacionDetalleGestion: '',
                idPuestoTrabajo: '',
                idEmpleado: '',
                recomendacion: '',
                plazo: null,
                ejecutada: '1',
                estado: 2,
            })
        },
        quitarMedidaImplantar(param, row, k) {
            let me = this;
            if (this.$route.params.id) {
                let listaMedidas = me.datosAcordionRiesgos[k].listaGestiones[row.index].listaMedidas.filter(x => x.estado == 2);
                if (listaMedidas[param.index].idEvaluacionDetalleMedida) {
                    listaMedidas[param.index].estado = 1;
                } else if (!listaMedidas[param.index].idEvaluacionDetalleMedida) {
                    listaMedidas[param.index].estado = 0;
                    for (let e in me.datosAcordionRiesgos[k].listaGestiones[row.index].listaMedidas) {
                        if (me.datosAcordionRiesgos[k].listaGestiones[row.index].listaMedidas[e].estado == 0) {
                            me.datosAcordionRiesgos[k].listaGestiones[row.index].listaMedidas.splice(e, 1);
                        }
                    }
                }
            } else {
                me.datosAcordionRiesgos[k].listaGestiones[row.index].listaMedidas.splice(param.index, 1);
            }
        },
        agregarGestion(k) {
            let me = this;
            me.datosAcordionRiesgos[k].listaGestiones.push({
                idEvaluacionDetalleGestion: '',
                idEvaluacionDetalleRiesgo: '',
                fechaGestion: moment().format('YYYY-MM-DD'),
                listaMedidas: [{
                    idEvaluacionDetalleMedida: '',
                    idEvaluacionDetalleGestion: '',
                    idPuestoTrabajo: '',
                    idEmpleado: '',
                    recomendacion: '',
                    plazo: null,
                    ejecutada: '1',
                    estado: 2,
                }],
                documentacion: null,
                urlDocumentacion: '',
                nombreDocumentacion: '',
                estado: 2,
            })
        },
        quitarGestion(row, k) {
            let me = this;
            if (this.$route.params.id) {
                let listaGestiones = me.datosAcordionAspectos[k].listaGestiones.filter(x => x.estado == 2);
                if (listaGestiones[row.index].idEvaluacionDetalleGestion) {
                    listaGestiones[row.index].estado = 1;
                } else if (!listaGestiones[row.index].idEvaluacionDetalleGestion) {
                    listaGestiones[row.index].estado = 0;
                    for (let e in me.datosAcordionRiesgos[k].listaGestiones) {
                        if (me.datosAcordionRiesgos[k].listaGestiones[e].estado == 0) {
                            me.datosAcordionRiesgos[k].listaGestiones.splice(e, 1);
                        }
                    }
                }
            } else {
                me.datosAcordionRiesgos[k].listaGestiones.splice(row.index, 1);
            }
        },
        // eliminarGestion(param, k) {
        //     let me = this
        //     me.$swal.fire({
        //         title: '¿Estas seguro de eliminar la gestión?',
        //         text: "¡No podrás revertir esto!",
        //         icon: 'warning',
        //         showCancelButton: false,
        //         confirmButtonColor: '#d33',
        //         confirmButtonText: 'Eliminar',
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             axios
        //                 .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-evaluacion-detalle-gestion", {
        //                     idEvaluacionDetalleGestion: param.item.idEvaluacionDetalleGestion,
        //                 }, {
        //                     headers: {
        //                         Authorization: `Bearer ${localStorage.token}`,
        //                     }
        //                 })
        //                 .then(function (response) {
        //                     let color = response.data.resultado == 1 ? "success" : "error";
        //                     me.swat(color, response.data.mensaje)
        //                     me.datosAcordionRiesgos[k].listaGestiones.splice(param.index, 1);
        //                 })
        //                 .catch(function (error) {
        //                     me.swat('error', 'Algo salió mal!')
        //                 });
        //         }
        //     })
        // },
        // eliminarMedida(param, row, k) {
        //     let me = this
        //     me.$swal.fire({
        //         title: '¿Estas seguro de eliminar la medida?',
        //         text: "¡No podrás revertir esto!",
        //         icon: 'warning',
        //         showCancelButton: false,
        //         confirmButtonColor: '#d33',
        //         confirmButtonText: 'Eliminar',
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             axios
        //                 .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-evaluacion-detalle-medida", {
        //                     idEvaluacionDetalleMedida: param.item.idEvaluacionDetalleMedida,
        //                 }, {
        //                     headers: {
        //                         Authorization: `Bearer ${localStorage.token}`,
        //                     }
        //                 })
        //                 .then(function (response) {
        //                     let color = response.data.resultado == 1 ? "success" : "error";
        //                     me.swat(color, response.data.mensaje)
        //                     me.datosAcordionRiesgos[k].listaGestiones[row.index].listaMedidas.splice(param.index, 1);
        //                 })
        //                 .catch(function (error) {
        //                     me.swat('error', 'Algo salió mal!')
        //                 });
        //         }
        //     })
        // },
        generarPrioridad(k) {
            let me = this;
            let prob = parseInt(me.datosAcordionRiesgos[k].probabilidad);
            let cons = parseInt(me.datosAcordionRiesgos[k].consecuencia);
            let total = prob * cons;

            if (total == 1 || total == 2) {
                me.datosAcordionRiesgos[k].prioridad = 'TOLERABLE';
            } else if (total >= 3 && total <= 11) {
                me.datosAcordionRiesgos[k].prioridad = 'SIGNIFICATIVO';
            } else if (total > 11) {
                me.datosAcordionRiesgos[k].prioridad = 'INTOLERABLE';
            }
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        }
    },
    watch: {

    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarPuestosTrabajo();
            this.listarEmpleados();
            if (this.$route.params.arrayRiesgos != null && this.$route.params.id == null) {
                this.agregarRiesgos();
            } else if (this.$route.params.arrayRiesgos != null && this.$route.params.id != null) {
                this.consultarEvaluacionDetalleGestion();
                this.consultarEvaluacionRiesgo();
                //this.consultarEvaluacionDetalleMedida();
            }
        }

    }

}
</script>
